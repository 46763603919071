
.link {
    color: var(--color-blue);
    text-decoration: underline;
}

.site-dropdown-context-menu {
    color: #777;
    background: #f7f7f7;
}

.first {
    color: green;
    background: var(--color-gray);
}

.second {
    color: var(--color-orange);
    background:var(--color-gray);
}

.third {
    color: red;
    background: var(--color-gray);
}


.empty {
    color: #777;
    width: auto;
    height: auto;
    background: #f7f7f7;
}
