.Navigation-Bar {
    position: fixed;
    margin-left: 10px;
    width: 24%;
    border: 1px solid #959595;
    border-radius: .5em;
}

.library {
    position: fixed;
    margin-top: 92px;
    margin-left: 10px;
    width: 24%;
    border: 1px solid #959595;
    border-radius: .5em;
    padding: .3em;
    max-height: 700px;
}

.library > :first-child > :first-child {
    overflow-x: hidden !important;
    box-sizing: border-box;
    padding-right: 17px; 
    padding-bottom: 17px;
}

.navigation-elem {
    display: flex;
    padding: 8px;
    padding-bottom: 0px;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #959595;
}

.navigation-elem:last-child {
    border-bottom: none;
}

.library-link {
    color: var(--color-gray)
}

.library-link:active {
    color: var(--primary-color)
}

.Navigation-class {
    margin-left: 15px;
    overflow-x: hidden;
    font-size: 11px;
}

.Navigation-attr {
    margin-left: 15px;
    overflow-x: hidden;
    font-size: 10px;
}

.batches {
    width: 100%;
    align-self: center;
}

.ant-select-selection {
    background-color: #d0b8f1;
}

div[style*="position: absolute"]:has(.ant-select-dropdown) {
    position: fixed !important;
}

.navigation-elem .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none !important;

}

.navigation-elem .ant-select-focused .ant-select-selector {
    border-color: transparent !important;
    box-shadow: none !important;
}

.navigation-elem .ant-select {
    position: relative;
}

.navigation-elem .ant-select:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0px;
    height: 1px;
    background: rgba(0, 159, 152, 0.6);
    filter: blur(1px);
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.navigation-elem .ant-select:hover:after {
    opacity: 1;
}


.navigation-elem .ant-select.ant-select-focused:after,
.navigation-elem .ant-select.ant-select-open:after {
    background: rgba(0, 159, 152, 1);
    opacity: 1;
}
