.ant-table-thead .ant-table-filter-trigger {
    color: white;
}

.ant-table-thead .ant-table-filter-trigger.active {
    color: rgb(174, 227, 255);
}


.sourceTable-link{
    color: var(--color-blue);
    cursor: pointer;
    text-decoration: underline;
    margin: 0;
}

.sourceTable-link:hover{
    opacity: 0.6;
}