@import '~antd/dist/antd.less';
@import (optional) '~@digatex/digatex-ui-lib/dist/@{theme}.less';

body {
  margin: 0;
  font-family: Calibri, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


header.ant-layout-header {
  background: @header-background;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.site-layout-background {
  background: #fff;
}

#logo {
  height: 48px;
}

#home-button > svg {
  position: relative;
  top: calc(50% - 16px);
}

.beautiful-link {
  color: @primary-color;
  cursor: pointer;
  text-decoration: underline;
}


.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

.primary-button {
  border-color: @primary-color;
  background-color: @primary-color;
  margin-left: 5px;
}

.primary-button:hover,
.primary-button:focus,
.primary-button:active,
.btn-primary:not(:disabled):not(.disabled):focus,
.btn-primary:not(:disabled):not(.disabled):active{
  border-color: @primary-color;
  background-color: @primary-color;
  filter: brightness(0.9);
  box-shadow: none;
}

.primary-table .ant-table-thead .ant-table-cell {
  background-color: @primary-color;
  color: white;
  text-align: center;
}

.nav-pills .nav-link.active .nav-link{
  background-color: @primary-color;
  color: @primary-color;
}

.nav-link{
  color: @primary-color;
  &:hover {
    color: @color-gray;
  }
}

.analysis-table .ant-table-cell.ant-table-column-has-sorters:hover{
  background-color: @primary-color;
  color: white;
}

.analysis-table .ant-table-cell.ant-table-column-sort.ant-table-column-has-sorters{
  background-color: @primary-color;
  color: white;
  
}

.ant-menu-title-content:hover{
  color: @primary-color;
}

.ant-table-column-sorter,
.ant-table-column-sorter-down,
.ant-table-column-sorter-up{
   color: white !important;
  &.active, :hover{
   color: @color-gray !important;
   cursor: pointer;
  }
}

.btn-primary.ant-btn-primary {
  border-color: @primary-color;
  background: @primary-color;
  border-radius: 5px;
}

.btn-primary.ant-btn-primary:disabled,
.btn-primary.ant-btn-primary:disabled:hover {
  border-color: @primary-color;
  background: @primary-color;
  border-radius: 5px;
  opacity: 0.3;
}

.btn-primary.ant-btn-primary:hover,
.btn-primary.ant-btn-primary:focus,
.btn-primary.ant-btn-primary:active {
  border-color: @primary-color;
  background: @primary-color;
  border-radius: 5px;
  filter: brightness(0.85);
}

.primary-card {
  .ant-card-head,
  .ant-card-body{
    padding: 10px;
  }
} 

.primary-card {
  .ant-card-head,
  .ant-card-head-title{
    padding-bottom: 5px;
  }
  .ant-card-head-title{
    padding: 0px;
  }
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: @color-gray !important;
}

.primary-card .ant-tabs-nav div .ant-tabs-tab {
  padding: 8px 16px;
  border: 1px;

  &:first-child {
      margin-left: 0;
  }
}

.primary-card div .ant-tabs-nav{
    margin: 0 0 11px 0;
}   

.primary-card.ant-card-bordered,
.primary-card.ant-tabs-top > .ant-tabs-nav,
.primary-card.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab{
    border-top: none;
    margin: 0px;
}

@theme: digatex;