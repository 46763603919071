.analysis-table-examples .ant-table-thead > tr > th {
    text-align: center;
    padding: 5px;
}
.analysis-table-examples .ant-table-tbody > tr > td {
    padding: 3px;
}

.example-table-item-link{
    color: var(--color-blue);
    cursor: pointer;
    text-decoration: underline;
    margin: 0;
}

.ant-pagination-total-text {
    display: flex !important;
    flex: 1 1 auto !important;
    gap: 10px;
}
