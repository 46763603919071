.rule {
    border: 1px solid #959595;
    border-radius: .25em;
    padding: .1em .3em;
    margin: .1em .3em .1em 0;
    line-height: 1.5em;
    cursor: default;
    display: inline-block;
}

/* .primary-button{
    margin: 0 0 5px 5px;
    display: inline;
    float: right;
} */