table.Stats tbody tr td {
    padding-top: 0;
    padding-bottom: 0;
    font-size: 14px;
}
table tbody tr td {
    line-height: 2.2em;
    text-align: center;
    border-right-width:  2.2px;
    border-color: #cacacc;
}

table thead tr th {
    line-height: 2.2em;
    text-align: center;
    background-color: #d9d9d9;
    border-right-width:  2.2px;
    border-color: #cacacc;
    vertical-align: middle;
}
.rounded-button {
    margin-left: 10px;
    border: 1px solid #959595;
    border-radius: .25em;
    padding: .1em .3em;
    line-height: 1.5em;
}

.match{
    background-color: greenyellow;
}
.link{
    color: blue;
    margin-right: 5px;
}
.active-link{
    color: red;
}
.MuiMenuItem-root {
    width: auto;
    overflow: hidden;
    font-size: 0.5rem;
    box-sizing: border-box;
    min-height: 48px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 300;
    line-height: 1.5;
    padding-top: 0;
    white-space: nowrap;
    letter-spacing: 0.00938em;
    padding-bottom: 0;
}

.MuiPaper-elevation8{
    min-width: 600px;
}
